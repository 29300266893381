'use client';

/* eslint-disable import/no-unassigned-import */
import '../styles/global/_index.scss';
import '../styles/tokens/root-variables.css';
import '../styles/tokens/root-variables-overrides.scss';
import 'react-loading-skeleton/dist/skeleton.css';
/* eslint-enable import/no-unassigned-import */
import classNames from 'classnames';
import { Merriweather, Nunito_Sans } from 'next/font/google';
import { useEffect } from 'react';

import { ErrorPageContentClient } from '@module/mdrt-org/shared/page-content-components';
import { logException } from '@shared/utils';

type ErrorPageProps = {
  error: Error & { digest?: string };
  reset: () => void;
};

const merriweather = Merriweather({
  subsets: ['latin'],
  variable: '--mdrt-font-families-merriweather',
  weight: ['400', '700', '900'],
});
const nunito = Nunito_Sans({
  adjustFontFallback: false,
  display: 'swap',
  fallback: ['Arial', 'sans-serif'],
  subsets: ['latin'],
  variable: '--mdrt-font-families-nunito-sans',
  weight: ['400', '600'],
});

const GlobalError = ({ error, reset }: ErrorPageProps) => {
  useEffect(() => {
    logException(error);
  }, [error]);

  return (
    <html lang="en">
      <body className={classNames(merriweather.variable, nunito.variable)}>
        <ErrorPageContentClient message={error.message} reset={reset} />
      </body>
    </html>
  );
};

export default GlobalError;
